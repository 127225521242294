.can-toggle {
  position: relative;
}
.can-toggle *, .can-toggle *:before, .can-toggle *:after {
  box-sizing: border-box;
}
.can-toggle input[type=checkbox] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.can-toggle input[type=checkbox][disabled] ~ label {
  pointer-events: none;
}
.can-toggle input[type=checkbox][disabled] ~ label .can-toggle__switch {
  opacity: 0.4;
}

.can-toggle input[type=checkbox]:checked ~ label .can-toggle__switch:before {
  content: attr(data-unchecked);
  left: 0;
}

.can-toggle input[type=checkbox]:checked ~ label .can-toggle__switch:after {
  content: attr(data-checked);
}
.can-toggle label {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  /*display: flex;*/
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.can-toggle label .can-toggle__switch {
  position: relative;
  /*margin:  0 auto;*/
}
.marginAuto {margin: 0 auto;}
.margin-2pcent {margin: 0 5%;}
.label-RNVE {width: 40%;}
.can-toggle label .can-toggle__switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 0;
  text-transform: uppercase;
  text-align: center;
}
.can-toggle label .can-toggle__switch:after {
  content: attr(data-unchecked);
  position: absolute;
  z-index: 5;
  text-transform: uppercase;
  text-align: center;
  background: white;
  transform: translate3d(0, 0, 0);
}
/* 
.can-toggle input[type=checkbox][disabled] ~ label {
  // color: rgba(119, 119, 119, 0.5);
} 
*/
.can-toggle input[type=checkbox]:focus  .can-toggle__switch, .can-toggle input[type=checkbox]:hover  .can-toggle__switch {
  background-color: black;
}
.can-toggle input[type=checkbox]:focus ~ label .can-toggle__switch:after,
.can-toggle input[type=checkbox]:hover ~ label .can-toggle__switch:after {
  color: black;
}

.can-toggle input[type=checkbox]:checked ~ label .can-toggle__switch {
  background-color: black;
}
.can-toggle input[type=checkbox]:checked ~ label .can-toggle__switch:after {
  color: black;
}
.can-toggle input[type=checkbox]:checked:focus ~ label .can-toggle__switch,
.can-toggle input[type=checkbox]:checked:hover ~ label .can-toggle__switch {
  background-color: black;
}
.can-toggle input[type=checkbox]:checked:focus ~ label .can-toggle__switch:after,
.can-toggle input[type=checkbox]:checked:hover ~ label .can-toggle__switch:after {
  color: black;
}
/*
.can-toggle label .can-toggle__label-text {
  flex: 1;
}
*/
.can-toggle label .can-toggle__switch {
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  /*background: #848484;*/
}
.can-toggle label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.5);
}
.can-toggle label .can-toggle__switch:after {
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: black;
}

.can-toggle input[type=checkbox]:checked ~ label .can-toggle__switch:after {
  transform: translate3d(45px, 0, 0);
}

.can-toggle label {
  font-size: 14px;
}
.can-toggle label .can-toggle__switch {
  height: 36px;
  flex: 0 0 95px;
  border-radius: 4px;
}
.can-toggle label .can-toggle__switch:before {
  left: 46px;
  line-height: 36px;
  width: 47px;
  padding: 0 5px;
}
.can-toggle label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 2px;
  width: 45px;
  line-height: 32px;
  padding: 0 7px;
}

can-toggle.switch input[type=checkbox]:focus ~ label .can-toggle__switch, .can-toggle.switch input[type=checkbox]:hover ~ label .can-toggle__switch {
  background-color: rgb(98, 110, 143);
}

.can-toggle.switch input[type=checkbox]:checked ~ label .can-toggle__switch {
  background-color: #183bd8;
}

.can-toggle.switch input[type=checkbox]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.switch input[type=checkbox]:checked:hover ~ label .can-toggle__switch:after {
  color: #000000;
}
.can-toggle.switch label .can-toggle__switch {
  transition: background-color 0.3s ease-in-out;
  background: rgb(98, 110, 143);
}
.can-toggle.switch label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.2); /*-- opacity label oui non --*/
}
.can-toggle.switch label .can-toggle__switch:after {
  transition: transform 0.3s ease-in-out;
}