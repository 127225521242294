html, body {
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 0.938rem;
	/*
	color: #10104e;
	*/
	color: #000;
	height: 100%;
}

body {background-color: #fff;}
h2 {
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 700;
	font-size: 1.25rem;
	color:#090989;
	border-bottom: #d4d8dc 1px solid;
	margin-bottom: 20px;
}
h3 {
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 700;
	font-size: 1.25rem;
	color: #000000;
	border-bottom: #d4d8dc 1px solid;
	margin-top: 20px;
}
.c-pointer {cursor: pointer;}
.c-default {cursor: default;}
/* ----- LOADER ----- */
.overlay {
	position: fixed;
	min-height: 100%;
	width: 100%;
	z-index: 2000;
	background-color : rgba(255, 255, 255);
	overflow-y: hidden;
	overflow-x: hidden;
}
.loader {
	position: relative;
	z-index: 5000;
	background-color: #fff;
	width: 220px;
	height:220px;
	margin: 15% auto;
}
.loader img {
	display:block;
	margin: 0 auto;
}
/* ----- ERROR ----- */
.error {
	padding: 150px 50px;
}
.error h1 {
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	font-size: 3.375rem;
	color: #fff;
}
.error p {
	color: #fff;
}
.btn-yellow {
	background-color: #ffd82a;
	border-color: #ffd82a;
	border-width: 1px;
	color: #10104e;
	border-radius: 0.25rem;
	padding: 0.375rem 0.75rem;
	font-weight: 600;
}
.btn-yellow:hover {
	background-color: #fff;
	border-color: #fff;
	border-width: 1px;
	color: #10104e;
}
/* ----- GRIDS ----- */
.row {
	margin-bottom: 1.563rem;
}
.no-marg {margin-bottom: 0;}
.grid {
	display: -ms-grid;
	display: grid;
	-ms-grid-row-gap: 1.563rem;
	grid-row-gap: 1.563rem;
	align-items: center;
	padding: 0;
}
.center {text-align: center;}
span {display: inline-flex; margin-right: 10px;}
label {margin-right: 0.625rem;}
.column-3 label, .column-3A label {
	min-width: 17.3%;
}
.column-2 label {
	min-width: 17.3%;
	max-width: 45%;
	padding-right: 10px;
}
.column-2 {
	-ms-grid-columns: 1fr 10px 1fr; 
	-ms-grid-template-rows: repeat 2, 1fr; 
	-ms-grid-column-gap: 10px;
	-ms-grid-row-gap: 1.563rem; 
	grid-template-columns: 1fr 1fr; 
	grid-template-rows: repeat 2, 1fr; 
	grid-column-gap: 10px; 	
}
.column-14-34 {
	display: grid; 
	-ms-grid-columns: 1.5fr 10px 3fr; 
 	grid-template-columns: 1.5fr 3fr; 
  grid-column-gap: 10px;
  	padding: 0;
}
.box-1 input {
	width: 66%;
}
.column-3 {
	-ms-grid-columns: 1fr 10px 1fr 10px 1fr; 
	-ms-grid-template-rows: repeat 2, 1fr; 
	-ms-grid-column-gap: 10px;
	-ms-grid-row-gap: 1.563rem; 
	grid-template-columns: 1fr 1fr 1fr; 
	grid-template-rows: repeat 2, 1fr; 
	grid-column-gap: 10px; 	
}
.column-3 p {display: inline-block;}
.column-3A {
	-ms-grid-columns: 0.5fr 10px 1fr 10px 1fr; 
	-ms-grid-template-rows: repeat 2, 1fr; 
	-ms-grid-column-gap: 10px;
	-ms-grid-row-gap: 1.563rem; 
	grid-template-columns: 0.5fr 1fr 1fr; 
	grid-template-rows: repeat 2, 1fr; 
	grid-column-gap: 10px;	
}
.column-3D {
	-ms-grid-columns: 1.5fr 10px 2fr 10px 2fr;
	grid-template-columns: 1.5fr 2fr 2fr;
}
.column-6 {
	-ms-grid-columns: 1.25fr 10px 1.25fr 10px 0.5fr 10px 0.5fr 10px 1.25fr 10px 1.25fr; 
	-ms-grid-template-rows: 1fr; 
	-ms-grid-column-gap: 10px;
	-ms-grid-row-gap: 1.563rem;
  -ms-justify-content: center;    
	grid-template-columns: 1.25fr 1.25fr 0.5fr 0.5fr 1.25fr 1.25fr; 
	grid-template-rows: 1fr; 
	grid-column-gap: 10px;
	grid-row-gap: 1.563rem;
  justify-content: center;
}

.column-7 {
	display: grid;
	-ms-grid-columns: 0.5fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr;
	grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-column-gap: 10px;
	/* grid-row-gap: ; */
}

.size1 {min-width: 120px;}
.size2 {min-width: 79px;}
/* ----- THEAD BLOC QUALITE MOYEN DE CONTACT -----*/
.thead-moyens-contact span {
	margin-left: 0;
	margin-right: 0;
}
/* -- fin qualité moyens de contact -- */
/* -- comportement d'achat actif -- */
.actif {
	display: inline-block;
	align-items: center;
}
div.actif label {min-width: 0%;}
.ID-line4 {
	-ms-grid-template-columns: repeat(3, 1fr);
	grid-template-columns: repeat(3, 1fr);
}
.ID-line4 div input {
	width: 67%;
}
.ID-line5 {
	-ms-grid-columns: 1fr 10px 1fr 10px 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	width: 65%;
}
.ID-line5 input[type="radio"] {
	margin-right: .5rem;
}
.alignTop {
	align-items: normal;
}
.w-32 {width: 32%;}
.w-40 {width: 40%;}
.w-53 {width: 53%;}
.w-60 {width: 60%;}
.w-65 {width: 65%;}
.w-70 {width: 70%;}
.w-72 {width: 72%;}
.w-79 {width: 79%;}
.w-80 {width: 80%;}
.w-85 {width: 85%;}

.infobulle {
	position: relative;
	top: -2px;
	background: #d8d8d8;
	color: #000;
	font-size: .6rem;
	font-weight: 700;
	padding: 0 6px;
	border-radius: 50%;
	cursor: help;
}
.infobulle:hover:after,
.infobulle:focus:after {
  content: attr(aria-label);
  position: absolute;
  top: -2.5em;
  left: 50%;
	transform: translateX(-50%);
  z-index: 1;
  white-space: nowrap;
  padding: 5px 14px;
  background: #000;
  color: #fff;
  border-radius: 4px;
  font-size: .8rem;
}
.prospect {
	position: relative;
	margin: 0;
	color: #0019ff;
}
[aria-label]:focus {
	outline: none;
}
/* ----- bloc identité client -----*/

.w-17 {min-width: 17%;}
.w-25 {min-width: 25%;}
.w-27 {min-width: 27%;}
/* ----- label comportement d'achat -----*/
.w-19 {width: 19.5%;}
/* ----- THEAD BLOC QUALITE MOYEN DE CONTACT -----*/
.padL-100 {padding-left: 100px}
.padL-25 {padding-left: 25%;}
.padL-60 {padding-left: 60px;}
.padL-90 {padding-left: 90px;}

.bg {
	background: url('../IMG/frise_avanci.png') bottom right no-repeat;
	background-size: 14.4%;
}
.hidden {
	display: none;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.tableRow {
	display: flex;
}
.borderBottom {
	border-bottom: #d4d8dc 1px solid !important;
	padding-bottom: 20px;
}
.borderBottom-thead {
	border-bottom: #d4d8dc 1px solid !important;
	padding-bottom: 5px;
	text-align: center;
}
/* -- comportement d'achat --*/
.borderBottom-achat {
	border-bottom: #d4d8dc 1px solid !important;
	padding-bottom: 5px;
}
.w-20 {padding-left: 20%;}
.w-30 {padding-left: 30%;}
/* ----- 
	ACCORDEON
 -----*/
.onglet {position:relative;}
.accordeon-title {
	display: inline-block;
	width: 100%;
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 700;
	font-size: 1.25rem;
	border-bottom: 0;
	margin-bottom: 0px;
	background-color: transparent;
	border: 0;
	text-align: left;
	cursor: pointer;
}
input[type="checkbox"].accordeon  {
	display: none;
}
input[type="checkbox"].accordeon + label::after {
	position: absolute;
	content: url('../IMG/plus.svg');
	width:20px;
	height: 20px;
	right: 0%;
}
input[type="checkbox"].accordeon:checked + label::after {
	position: absolute;
	content: url('../IMG/moins.svg');
	width:20px;
	height: 20px;
	right: 0%;
	top: -8px;
}

table {
	font-family: 'Bebas Neue', cursive;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 0.938rem;
	color: #10104e;
}
th {
	font-weight: 400;
}
td {text-align:center;}
.left  {text-align:left;}
h2 span {opacity: .5;}
label {
	font-family: 'Roboto Condensed', sans-serif;
}
input[type="text"]  {
	display: inline-block;
  /*width: 100%;*/
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

#navTop ul li {
	display: inline-block;
}
#navTop li {padding: 0 10px;}
#navTop button {
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 0.938rem;
}
.deconnexion, .exporter {
	border: 0;
	background-color: transparent;
}
.deconnexion img {
	transform: rotate(180deg);
	margin-left: 5px;
}
.exporter img {
	margin-left: 5px;
}
.deconnexion:hover, .exporter:hover {
	text-decoration: underline;
}
.first {
	max-width: 1610px;
	width: 100%;
	background-color: #fff;
	border-radius: 10px;
	-webkit-box-shadow: 2px 2px 10px 1px rgba(107,115,115,0.32); 
	box-shadow: 2px 2px 10px 1px rgba(107,115,115,0.32);
	padding: 30px;
	margin: 0 auto 30px;
}
.box {
	max-width: 1610px;
	width: 100%;
	background-color: #fff;
	border-radius: 10px;
	-webkit-box-shadow: 2px 2px 10px 1px rgba(107,115,115,0.32); 
	box-shadow: 2px 2px 10px 1px rgba(107,115,115,0.32);
	padding: 20px 30px;
	margin: 30px auto;
}
.marg-b0{
	margin-bottom: 0;
}

.bloc-1, .bloc-2, .bloc-3, .bloc-4 {
	padding-top: 1.563rem;;
}
.container-home {
	margin: 0 auto;
	max-width: 1280px;
	width: 100%;
	padding: 0 30px;
}
.hero {
	display: block;
	position: relative;
	background-color: #090989;
	background-position: 50%;
}
.hero-deco {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 76%;
}
.hero-img {
	position: relative;
	z-index: 50;
	margin-bottom: -125px;
	text-align: right;
}
.header {padding-top:2.5%;}
.pad-log {padding-top: 10%;}
.log-title {
	font-size: 22px;
	font-weight: 700;
	margin-bottom: 1.5rem;
	color: white;
	letter-spacing: 0.5px;
}
.center {text-align: center;}
.padd0 {padding: 0;}
.form-label {margin-bottom: 0;}
.form-check-inline {
	padding: 10px 0;
}
.form-control {
	text-align: center;
	color: #10104e;
	margin-bottom: 0.5rem;
}
.invalid-feedback {display: block;}
.search-client-error {padding-left: 36%;}

/* ---
écran recherche
--- */
#recherche .col {
	margin-right: 0;
}
.champs {
	padding: 10px 0;
}
.champs label {
	display: inline-block;
	width: 35%;
	margin-right: 0;
}
.champs > div {
	display: inline-block;
	width: 62%;
}
.btn-rch {
	text-align: right;
}
.btn-rch button {width:87%}

.btn {
	background-color: #090989;
	border-color: #090989;
	width: 100%;
}
.btn:hover {
	background-color: #ffd82a;
	border-color: #ffd82a;
	color: #10104e;
}
#login {
	position: relative;
	z-index: 1000;
	background-color: #fff;
	max-width: 430px;
	margin: 0 auto;
	padding: 30px;
	border-radius: 40px 40px 0px 40px;
	-webkit-box-shadow: 2px 2px 10px 1px rgba(107,115,115,0.32); 
	box-shadow: 2px 2px 10px 1px rgba(107,115,115,0.32);
	}
.powered {
	display: block;
	position:absolute;
	padding: 15px 20px;
	font-size: 12px;
}
.sign {
	font-size: 12px;
	padding-bottom: 50px;
}
a.pass {
	display: inline-block;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 1rem;
	text-align: center;
	background-color: transparent;
	border:none;
	color:#6c757d;
	text-decoration: underline;
	margin-top: 10px;
}
.deconnexion a {color: #6c757d;}

.btn-rch-client {
	text-align: right;
}
.btn-rch-client button {
	max-width: 200px;
}

/* ---
listing client
--- */
.page{
	min-height: 100vh;
}
.alignLeft {text-align: left;}
.alignRight {
	text-align: right;
	padding-top: 20px;
}
#pagination {
	font-family: 'Bebas Neue', cursive;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 0.938rem;
	color: #090989
}
.page-link {
	color: #090989;
	border:0;
}
.page-link:hover {color: black;}
.page-item.selected-page > .page-link {
	color: red;
}
#listing-client button {
	border: 0;
	font-weight: 700;
	color: black;
	background-color: transparent;
}
#listing-client button:hover {
	text-decoration:underline;
}
#listing-client thead {text-align: center;}
.paddTop {padding-top: 16px;}

/* Medium devices (tablets, 768px and up) */

@media (max-width: 1024px) and (min-width: 768px) {
	.grid {
		grid-row-gap:1rem;
	}
	.padL-60 {padding-left:0;}
	.ID-line5 {
		width: 85%;
	}
	
	.bloc-3 .column-3 input {width:35%;}
	.actif {display: block;}
}

@media (min-width: 1400px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1610px;
	}
}
@media (max-width: 575.98px) {
	.mobile {display: none;}
	.grid {
		display: inline-block;
	}
	.column-3 input[type="text"],
	.column-2 input[type="text"],
	.column-3A input[type="text"],
	.column-3B input[type="text"],
	.column-4 input[type="text"],
	.column-3D input[type="text"],
	.column-14-34 input[type="text"],
	.column-6 input[type="text"],
	.ID-line4 input[type="text"],
	.ID-line5 input[type="text"]{
		width: 100%;
	}
	.container-home {
		padding: 0 30px 50px 30px;
	}
	.hero-img, .hero-deco {display: none;}
	/* -- écran recherche -- */
	.col {flex:auto;}
	.col-3 {width:72.5%;}
	/* -- fin écran recherche -- */
	.col-2 {width:50%;}
	.col-4, .col-6  {width: 100%;}
	.deconnexion {
		text-align:left;
		padding-top: 10px;
		padding-bottom: 10px;
	}
	/* -- écran listing clients -- */
	#listing-client td {display: inline-block;width: 100%;}
  #listing-client th {display: none;}
	/* -- fin listing clients -- */
	.col {
		display: inline-block;
		width: 100%;
	}
	.col input[type="text"] {
		width:100%;
	}
}
